import { Section } from 'components/Layout/Layout'
import { HeroCarousel } from '@ssgat/react-components'
import useFeatured from '@ssgat/common/providers/useFeatured'
import { useRouter } from 'next/router'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { ContainerPropsType } from 'types/containerPropsType'
import { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
export type HeroContainerType = {}

export const HeroContainer: FC<HeroContainerType> = () => {
    const { featured } = useFeatured()
    const { asPath } = useRouter()

    const pageFeatured = featured?.[asPath]
    if (!pageFeatured?.length) return null
    return (
        <Section toMobileEdge showDivider={false}>
            <HeroCarousel featuredItems={pageFeatured} />
        </Section>
    )
}

export const heroContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getFeatured'>(store, 'getFeatured', {
                pageSlug,
            }),
        ]),
    }
}

export default HeroContainer
