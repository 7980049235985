
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UseFeatured = {
  featured: AppState['featured'],
  featuredLoading: AppState['featuredLoading'],
  featuredError: AppState['featuredError'],
  getFeatured: (data:RequestTypes['getFeatured'], callbacks?:Callbacks)=>void,
}

export default function useFeatured():UseFeatured {
  const {
    featured, featuredLoading, featuredError } = useSelector((state:AppState)=>({
    featured: state.featured,
    featuredLoading: state.featuredLoading,
    featuredError: state.featuredError,
  }));
  const dispatch = useDispatch();
  const getFeatured = useCallback((data:RequestTypes['getFeatured'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getFeatured(data, callbacks))
  },[dispatch])
  return {
    featured,
    featuredLoading,
    featuredError,
    getFeatured,
  }
}
