import { FaqDataType } from '@ssgat/react-components'

export type FaqContentRawType = {
    question: FaqDataType['header']
    answer: FaqDataType['body']
}

export type FaqRawType = { title: string; content?: FaqContentRawType[] }

const mapFaqs: (faq: FaqRawType) => FaqDataType[] = ({ content }) => {
    const reducerCallback = (
        acc: FaqDataType[],
        { question: header = '', answer: body = '' }: FaqContentRawType,
        id: FaqDataType['id']
    ) => {
        return [...acc, { id, header, body }]
    }

    return content?.reduce(reducerCallback, []) || []
}

export { mapFaqs }
