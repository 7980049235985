import { ContentType } from '@ssgat/react-components'

type filterContentType = (args: filterArgsType) => {
    content: ContentType[]
}

const mappers = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    withIntroContent: ({ intro: { title, ...intro } = {} }: ContentType) => ({
        intro,
        content: [],
    }),
    withSEOContent: ({ content = [] }: ContentType) => ({ content }),
}

// todo: this should be uppercase
type filterArgsType = {
    type: keyof typeof mappers
    content: ContentType[]
}

export const filterContent: filterContentType = ({ type, content }) => ({
    [type]: true,
    content: content.map(mappers[type]),
})

// todo: this should be uppercase
type contentType = (data: ContentType[]) => boolean

export const hasIntroContent: contentType = (data) =>
    data.some(({ intro: { content = null } = {} }) => !!content)

export const hasSeoContent: contentType = (data) =>
    data.some(({ content }) => content?.length > 0)
