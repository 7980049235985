import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, Callbacks } from "../app-actions";
import { AppState, RequestTypes, TParlay } from "../state-type";

type useParlays = {
  parlays?: TParlay[];
  parlaysLoading: AppState["parlaysLoading"];
  parlaysError: AppState["parlaysError"];
  getParlays: (data: RequestTypes["getParlays"], callbacks?: Callbacks) => void;
};

export default function useParlays(key="all"): useParlays {
  const { parlays, parlaysLoading, parlaysError } = useSelector(
    (state: AppState) => ({
      parlays: state.parlays?.[key],
      parlaysLoading: state.parlaysLoading,
      parlaysError: state.parlaysError,
    })
  );
  const dispatch = useDispatch();
  const getParlays = useCallback(
    (data: RequestTypes["getParlays"], callbacks?: Callbacks) => {
      return dispatch(AppActions.getParlays(data, callbacks));
    },
    [dispatch]
  );
  return {
    parlays,
    parlaysLoading,
    parlaysError,
    getParlays,
  };
}
