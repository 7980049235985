import { FC } from 'react'
import { addClass, Content } from '@ssgat/react-components'
import { Section } from '../../Layout/Layout'
import { ContentProps } from './Content.types'
import { filterContent, hasIntroContent, hasSeoContent } from './utils'
import styles from './Content.module.scss'

export const ContentWrapper: FC<ContentProps> = ({
    content,
    children,
    shouldHideTopDivider,
}) => {
    const introContent = hasIntroContent(content)
        ? filterContent({ type: 'withIntroContent', content })
        : null

    const seoContent = hasSeoContent(content)
        ? filterContent({ type: 'withSEOContent', content })
        : null

    const hiddenDividerClass = addClass(
        shouldHideTopDivider ? styles.hiddenDivider : ''
    )

    return (
        <>
            {introContent && (
                <Section
                    showDivider={!shouldHideTopDivider}
                    className={hiddenDividerClass || undefined}
                >
                    <Content {...introContent} />
                </Section>
            )}
            {children}
            {seoContent && (
                <Section>
                    <Content {...seoContent} />
                </Section>
            )}
        </>
    )
}

export { ContentWrapper as Content }
