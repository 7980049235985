import { FC } from 'react'
import { Content } from 'components/ContentContainer/components'
import useContent from '@ssgat/common/providers/useContent'
import { TContent } from '@ssgat/common/state-type'
import usePageSlug from 'components/util/usePageSlug'
import { mapFaqs } from 'project/mapFaqs'
import { Section } from '../Layout/Layout'
import { Faq } from '@ssgat/react-components'

export type ContentContainerType = {
    noContentElement?: JSX.Element
    shouldHideTopDivider?: boolean
    withFaqs?: boolean
}

export const ContentContainer: FC<ContentContainerType> = ({
    children,
    noContentElement,
    shouldHideTopDivider,
    withFaqs,
}) => {
    const { content } = useContent()
    const pageSlug = usePageSlug()
    const pageContent = content?.[pageSlug] || []

    const [{ faqs: rawFaqs = null, breadcrumbs = null, ...contentwithFaqs }] =
        pageContent?.length ? pageContent : [{}]
    const faqs = withFaqs && rawFaqs ? mapFaqs(rawFaqs as any) : []
    const renderFaqs = withFaqs && !!faqs?.length && rawFaqs
    const contentData: TContent[] = withFaqs
        ? ([contentwithFaqs] as TContent[])
        : pageContent
    return (
        <>
            <Content
                shouldHideTopDivider={shouldHideTopDivider}
                content={
                    //todo: react-components types for faqs is wrong
                    contentData as any
                }
            >
                {!pageContent?.length && noContentElement}
                {children}
            </Content>
            {renderFaqs && (
                <Section>
                    <Faq faqs={faqs} title={rawFaqs.title} />
                </Section>
            )}
        </>
    )
}

export default ContentContainer
