import { Section } from 'components/Layout/Layout'
import useFeaturedNews from '@ssgat/common/providers/useFeaturedNews'
import {
    EditorialGrid,
    SubHeader,
    useIsMobileWidth,
} from '@ssgat/react-components'
import usePageSlug from 'components/util/usePageSlug'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { FC } from 'react'
import { ContainerPropsType } from 'types/containerPropsType'

export type EditorialGridContainerType = {
    limit?: number
    topNews?: boolean
    title?: string
}

export const EditorialGridContainer: FC<EditorialGridContainerType> = ({
    limit,
    topNews,
    title,
}) => {
    const { featuredNews } = useFeaturedNews()
    const pageSlug = usePageSlug()
    const isMobileWidth = useIsMobileWidth()
    const [news] = featuredNews?.[pageSlug] || []

    if (!news?.articles?.length) {
        return null
    }
    return (
        <Section toMobileEdge showDivider={!isMobileWidth}>
            {title && <SubHeader title={title} />}
            <EditorialGrid
                {...news}
                articles={limit ? news.articles.slice(0, limit) : news.articles}
                topNews={topNews}
                featuredArticle={true}
            />
        </Section>
    )
}

export const editorialGridContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getFeaturedNews'>(store, 'getFeaturedNews', {
                pageSlug,
            }),
        ]),
    }
}

export default EditorialGridContainer
