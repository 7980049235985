import { useDispatch, useSelector } from "react-redux";
import { AppActions, Callbacks } from "../app-actions";
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from "react";

type UseFeaturedNews = {
  featuredNews: AppState["featuredNews"];
  featuredNewsLoading: AppState["featuredNewsLoading"];
  featuredNewsError: AppState["featuredNewsError"];
  getFeaturedNews: (
    data: RequestTypes["getFeaturedNews"],
    callbacks?: Callbacks
  ) => void;
};

export default function useFeaturedNews(): UseFeaturedNews {
  const { featuredNews, featuredNewsLoading, featuredNewsError } = useSelector(
    (state: AppState) => ({
      featuredNews: state.featuredNews,
      featuredNewsLoading: state.featuredNewsLoading,
      featuredNewsError: state.featuredNewsError,
    })
  );
  const dispatch = useDispatch();
  const getFeaturedNews = useCallback(
    (data: RequestTypes["getFeaturedNews"], callbacks?: Callbacks) => {
      return dispatch(AppActions.getFeaturedNews(data, callbacks));
    },
    [dispatch]
  );
  return {
    featuredNews,
    featuredNewsLoading,
    featuredNewsError,
    getFeaturedNews,
  };
}
