
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes, TPick } from "../state-type";
import { useCallback, useMemo } from 'react';
type UseNextUp = {
  nextUp?: TPick[],
  nextUpLoading: AppState['nextUpLoading'],
  nextUpError: AppState['nextUpError'],
  getNextUp: (data:RequestTypes['getNextUp'], callbacks?:Callbacks)=>void,
}

export default function useNextUp(key="all"):UseNextUp {
  const {
    nextUp, nextUpLoading, nextUpError } = useSelector((state:AppState)=>({
    nextUp: useMemo(()=>{
      return state.nextUp?.[key] && state.nextUp?.[key].filter((item)=>{
        return new Date(item.startTimeString).valueOf()> Date.now().valueOf()
      })
    },[state.nextUp,key]),
    nextUpLoading: state.nextUpLoading,
    nextUpError: state.nextUpError,
  }));
  const dispatch = useDispatch();
  const getNextUp = useCallback((data:RequestTypes['getNextUp'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getNextUp(data, callbacks))
  },[dispatch])
  return {
    nextUp,
    nextUpLoading,
    nextUpError,
    getNextUp,
  }
}
