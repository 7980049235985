import useParlays from '@ssgat/common/providers/useParlays'
import {
    MultipleShortcutCard,
    SliderNav,
    useIsMobileWidth,
} from '@ssgat/react-components'
import { Section } from 'components/Layout/Layout'
import usePageSlug from 'components/util/usePageSlug'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { FC } from 'react'
import { ContainerPropsType } from 'types/containerPropsType'

export type ParlaysContainerType = {
    title?: string
    href?: string
}

export const ParlaysContainer: FC<ParlaysContainerType> = ({ title, href }) => {
    const pageSlug = usePageSlug()
    const { parlays } = useParlays(pageSlug)
    const isMobileWidth = useIsMobileWidth()

    if (!parlays?.length) return null
    return (
        <Section toMobileEdge showDivider={!isMobileWidth}>
            <SliderNav alignLastItem title={title} href={href}>
                {parlays?.map((parlay) => (
                    <MultipleShortcutCard {...parlay} key={parlay?.id} />
                ))}
            </SliderNav>
        </Section>
    )
}

export const parlaysContainerProps: ContainerPropsType = async (
    store,
    pageSlug: string
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getParlays'>(store, 'getParlays', {
                pageSlug,
            }),
        ]),
    }
}

export default ParlaysContainer
