import { useDispatch, useSelector } from "react-redux";
import { AppActions, Callbacks } from "../app-actions";
import { AppState, RequestTypes, TPick } from "../state-type";
import { useCallback } from "react";

type UsePicks = {
  picks?: TPick[];
  picksLoading: AppState["picksLoading"];
  picksError: AppState["picksError"];
  getPicks: (data: RequestTypes["getPicks"], callbacks?: Callbacks) => void;
};

export default function usePicks(pageSlug="all"): UsePicks {
  const { picks, picksLoading, picksError } = useSelector(
    (state: AppState) => ({
      picks: state.picks?.[pageSlug],
      picksLoading: state.picksLoading,
      picksError: state.picksError,
    })
  );
  const dispatch = useDispatch();
  const getPicks = useCallback(
    (data: RequestTypes["getPicks"], callbacks?: Callbacks) => {
      return dispatch(AppActions.getPicks(data, callbacks));
    },
    [dispatch]
  );

  return {
    picks,
    picksLoading,
    picksError,
    getPicks,
  };
}
