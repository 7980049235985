import usePicks from '@ssgat/common/providers/usePicks'
import {
    CardGrid,
    ImageLoadingContextProvider,
    ImageLoadingType,
    SelectionCard,
    useIsMobileWidth,
} from '@ssgat/react-components'
import { Section } from '../Layout/Layout'
import usePageSlug from 'components/util/usePageSlug'
import { ContainerPropsType } from 'types/containerPropsType'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { FC } from 'react'

export type BestBetsContainerType = {
    title?: string
}

export const BestBetsContainer: FC<BestBetsContainerType> = ({ title }) => {
    const pageSlug = usePageSlug()
    const { picks } = usePicks(pageSlug)

    // todo `getByBreakpoint` uses the `useIsMinWidth` hook.
    // This violates hook rules, such as "call hooks from the top level of a component",
    // and "call hooks from within a react component". I don't think we should
    // inherit this anti-pattern. Let's revisit this when we're ready.
    // const numberOfCardsToEagerLoad = getByBreakPoint<number>(numberOfCardsConfig, 2)
    const numberOfCardsToEagerLoad = 2
    const isMobileWidth = useIsMobileWidth()

    const selectLoadingStrategy: (
        index: number
    ) => ImageLoadingType['loading'] = (index) =>
        index < numberOfCardsToEagerLoad ? 'eager' : 'lazy'
    if (!picks?.length) {
        return null
    }
    return (
        <Section toMobileEdge showDivider={!isMobileWidth}>
            <CardGrid mobileSlider title={title}>
                {picks?.map((pick, index) => (
                    <ImageLoadingContextProvider
                        key={pick?.id || index}
                        loading={selectLoadingStrategy(index)}
                    >
                        <SelectionCard {...pick} betslip={undefined} />
                    </ImageLoadingContextProvider>
                ))}
            </CardGrid>
        </Section>
    )
}

export const bestBetsContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getPicks'>(store, 'getPicks', {
                pageSlug,
                confidence: '3',
                count: 6,
            }),
        ]),
    }
}

export default BestBetsContainer
