import useNextUp from '@ssgat/common/providers/useNextUp'
import { NextUpWidget } from './NextUpWidget'
import { Section } from 'components/Layout/Layout'
import { SubHeader, useIsMobileWidth } from '@ssgat/react-components'
import usePageSlug from 'components/util/usePageSlug'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { ContainerPropsType } from 'types/containerPropsType'
import { FC } from 'react'

export type NextUpContainerType = {
    title?: string
    href?: string
    nextUpProps?: {
        //todo: I Think react-components nextupwidget should export its type
        borderStyleSmooth?: boolean
        showCardHeader?: boolean
        showFilter?: boolean
        filterTitle?: string
        withoutGap?: boolean
    }
}

export const NextUpContainer: FC<NextUpContainerType> = ({
    nextUpProps,
    title,
    href,
}) => {
    const pageSlug = usePageSlug()
    const { nextUp } = useNextUp(pageSlug)
    const isMobileWidth = useIsMobileWidth()
    if (!nextUp?.length) return null
    return (
        <Section toMobileEdge showDivider={false}>
            {isMobileWidth && !!title && (
                <SubHeader title={title} href={href} />
            )}
            <NextUpWidget events={nextUp} {...nextUpProps} />
        </Section>
    )
}

export const nextUpContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getNextUp'>(store, 'getNextUp', {
                pageSlug,
            }),
        ]),
    }
}
