import {
    ImageLoadingContextProvider,
    NextUpWidget as NextUpWidgetLib,
    NextUpWidgetType,
} from '@ssgat/react-components'
import { FC } from 'react'

export const NextUpWidget: FC<NextUpWidgetType> = (nextUpProps) => (
    <ImageLoadingContextProvider loading="eager">
        <NextUpWidgetLib {...(nextUpProps as NextUpWidgetType)} />
    </ImageLoadingContextProvider>
)
